.zmwebsdk-makeStyles-leftButtonsContainer-34,
#app-signal,
button#meeting-info-indicationmeeting-info-container__wrapper.meeting-info-icon__icon-wrap.ax-outline-blue,
.SvgEncryption {
  display: none !important;
}

.css-view-175oi2r:focus-visible{
  outline: unset !important;
}
